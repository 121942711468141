<template>
    <div class='suppot-page'>
        <div class='support-main'>
            <div class='support-line' v-for="item in supportList" :key="item.bankName">
                <img class='support-img' :src='item.bankIcon' alt='' />
                <div class='support-text'>{{item.bankName}}</div>
            </div>
            <!-- <div class='support-line'>
                <img class='support-img' src='' alt='' />
                <div class='support-text'>中国银行储蓄卡</div>
            </div> -->
        </div>
        
    </div>
</template>
<style lang="less" src="./index.less" scoped></style>
<script>
import { onMounted, toRefs,reactive } from "vue"
import APP from '../../utils/APP'
import API from '../../server/api'


export default ({
    setup() {
        const state=reactive({
            checked:false,//协议选中
            supportList:[]
        })
        const initBank= async()=>{
           const result= await API.bankSupport({})
           state.supportList=result.supportList
        }
        onMounted(()=>{
            initBank()
        })
        return{
            ...toRefs(state),
            initBank
        }
    },
})
</script>